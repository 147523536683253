.timer--demo {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-image: url("/src/img/demo/boxer.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 25%;
    justify-content: center;
    align-items: center;
    container-type: inline-size;
}

.timer--demo .timer--demo-fullexperience {
    padding-top: 1em;
}

.phonedemo--display .timer--demo .timer--demo-fullexperience {
    display: none;
}

.timer--demo .timer--demo-fullexperience a {
    color: rgb(146, 146, 146);
}

.timer {
    display: flex;
    font-weight: 900;
    color: var(--accent);
    height: 90%;
    align-items: center;
    font-size: 100px;
}

#timer--onedigit {
font-size: 1.8em;
}

.controls {
    display: flex;
    width: 100%;
    height: 10%;
    min-height: 30px;
}

.controls--button {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: "Martian Mono", monospace;
    font-weight: 200;
    font-size: 7px;
    text-transform: uppercase;
}

#start {
    background-color: var(--accent);
    color: var(--reset);
}

#stop {
    background-color: var(--stop);
    color: var(--accent);
}

#reset {
    background-color: var(--reset);
    color: var(--accent);
}

@container (width > 260px) {
    .timer {
        font-size: 150px;
    }
    .controls--button {
        font-size: 13px;
    }
}

@container (width > 300px) {
    .timer {
        font-size: 200px;
    }
    .controls--button {
        font-size: 13px;
    }
}

@container (width > 360px) {
    .timer {
        font-size: 220px;
    }
    .controls--button {
        font-size: 15px;
    }
}

@container (width > 560px) {
    .timer {
        font-size: 350px;
    }
    .controls--button {
        font-size: 18px;
    }
}

@container (width > 900px) {
    .timer {
        font-size: 550px;
    }
    .controls--button {
        font-size: 18px;
    }
    #timer--onedigit {
        font-size: 1em;
        }
}