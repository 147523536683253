.app {
  display: none;
}

body {
  font-family: "Martian Mono", monospace;
}

.web {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 400px 1fr;
  grid-template-rows: 1fr 700px 1fr;
  background-color: var(--weights-bg);
  transition: grid-template-columns var(--transition-duration);
}

.timerBG {
  background-image: url("img/bg/timer.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.4;
  z-index: 0;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: -1;
  margin-right: 200px;
  transition: opacity 3s 0s ease-out;
  mix-blend-mode:screen;
  pointer-events: none;
}

.weightsBG {
  background-image: url("img/bg/weights.jpg");
  background-size: cover;
  background-position: center center;
  opacity: 0.3;
  z-index: 0;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: -1;
  margin-left: 200px;
  transition: opacity 3s 0s ease-out;
  mix-blend-mode: screen;
  pointer-events: none;
}

.invisibleBG {
  opacity: 0;
  transition: opacity 0.5s ease-out;

}

.web--animate-timer {
  grid-template-columns: 2fr 400px 0.5fr;
  transition: grid-template-columns var(--transition-duration);
}

.web--animate-weights {
  grid-template-columns: 0.5fr 400px 2fr;
  transition: grid-template-columns var(--transition-duration);
}

.web--content {
  display: flex;
  padding: 1.5em;
}

.web--content#bold {
  display: flex;
  justify-content: center;
}

.web--timer {
  justify-content: flex-end;
  background-color: var(--timer-bg);
  margin-right: -200px;
  padding-right: 200px;
  grid-column-start: 1;
  grid-row-start: 2;
}

.web--weights {
  background-color: var(--weights-bg);
  margin-left: -200px;
  padding-left: 200px;
  grid-column-start: 3;
  grid-row-start: 2;
}

/* MARK: DEMO
*/
.web--demo {
  display: flex;
  flex-direction: column;
  z-index: 2;
  grid-row-start: 2;
  grid-column-start: 2;
}

.web--download {
  padding: 8%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1em;
}

.web--download-qr {
  background: white;
  padding: 0.5em;
  border-radius: 5%;
}

@media only screen and (max-width: 800px) {
  .web {
    display: none;
  }

  .app {
    display: block;
    height: 100vh;
  }

}