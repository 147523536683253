.menu {
    padding: 1.5em;
    margin-left: -200px;
    text-align: right;
    grid-column-start: 3;
    grid-row-start: 1;
  }

  .menu a {
    padding-left: 2em;
    color: #2a2729;
    text-decoration: none;
    transition: color 0.7s ease-in-out;

  }



  .menu a.active {
    color: var(--accent);
  }

  .menu a:hover {
    color: white;
    transition: color 0.2s ease-in-out;
  }

  @media only screen and (max-width: 800px) {  
    .menu {
      grid-column-start: 2;
    }
  }