.footer {
    margin-right: -200px;
    background-color: var(--timer-bg);
    padding: 1.5em;
    display: flex;
    align-items: flex-end;
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .footer a {
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
  }

  .footer a.active {
    color: white;
    opacity: 0.5;
  }