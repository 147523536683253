.phonedemo--timer {
    background-image: url("/src/img/demo/display.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 0.55;
}

.phonedemo--display {
    height: 91%;
    max-width: 80%;
    border-radius: 15% / 7%;
    overflow: hidden;
    aspect-ratio: 0.477;
    opacity: 100%;
    transition: opacity 0.5s;
}

.phonedemo--display-hidden {
    opacity: 0%;
    transition: opacity 0.5s;
}