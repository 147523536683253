.end {
    padding: 1.5em 0 0 1.5em;
    margin-left: -200px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    grid-column-start: 3;
    grid-row-start: 3;
    gap: 1.5em;
  }

  .end img {
    height: 2.5em;
    padding: 0;
    margin: 0;
  }

  .end .social {
    height: 2em;
    padding-bottom: 0.5em;
  }

  .end a {
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
  }

  .end a.active {
    color: white;
    opacity: 0.5;
  }

  @media only screen and (max-width: 800px) {  
    .end {
      grid-column-start: 2;
    }
  }