p, ul {
    font-family: "Montserrat", sans-serif;
    line-height: 1.5em;
}

h2 {

    font-family: "Martian Mono", monospace;
    padding: 0px;
    padding-top: 1em;
    margin: 0px;
}

h2 img {
    height: 2em;
    filter: drop-shadow(#0000006b 0.1rem 0.5rem 0.5rem);
}

h2 a {
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-decoration: none;
    color: #232122;
}

.app-detail {
    width: 100%;
    max-width: 800px;
    container: content / inline-size;
    opacity: 0;
}

.visible {
    opacity: 1;
    transition: opacity var(--fade-duration);
  }

  .app-detail--features {
    padding-top: 1em;

  }

.app-detail--features img {
    height: 60px;
    padding-right: 2em;
    filter: brightness(1);
    transition: filter 0.7s ease-in-out;

}

.app-detail--features img:hover {
    filter: brightness(3);
    transition: filter 0.2s ease-in-out;

}


ul {
    padding-left: 0.7em;
    margin: 0;
    list-style-type: "+ "
}

.columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
}

@container content (width < 600px) {
    .columns {
        grid-template-columns: auto;
    }
    
.app-detail--features-hidden {
    display: none;
}
}

@container content (width < 450px) {
    .secondCol {
        display: none;
    }
    
}

