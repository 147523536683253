.mobile--app-features img {
    padding-right: 1.5em;
    height: 60px;
}

.mobile--App {
    margin-bottom: 3em;
}

.mobile--App h2 {
    padding: 0.5em 0;
}

.mobile--App:last-of-type {
    margin-bottom: 0em;
}

.mobile--app-screenshots img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .mobile--app-features .app-detail--features-hidden {
        display: none;
    }
}